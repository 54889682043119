import React, { useEffect } from 'react';

const DJing: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://www.instagram.com/djrgbee/";
  }, []);

  return <p>Redirecting to Instagram...</p>;
};

export default DJing;
