import React, { useEffect } from 'react';

const LinkedIn: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://www.linkedin.com/in/nathanorme23/";
  }, []);

  return <p>Redirecting to LinkedIn...</p>;
};

export default LinkedIn;
