import React, { useEffect } from 'react';

const DiscogsAPI: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://rgbnathan-discogs-api.co.uk/";
  }, []); // Only run once when the component mounts

  return <p>Redirecting to Discogs Query API...</p>;
};

export default DiscogsAPI;
