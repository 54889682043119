import React, { useEffect } from 'react';

const Github: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://github.com/NathanOrme";
  }, []);

  return <p>Redirecting to GitHub...</p>;
};

export default Github;
