import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import DiscogsAPI from './components/DiscogsAPI';
import DiscogsCollection from './components/DiscogsCollection';
import NoDogsAllowed from './components/NoDogsAllowed';
import LinkedIn from './components/LinkedIn';
import Github from './components/Github';
import Navbar from './components/Navbar';
import DJing from './components/DJing';
import CanvasBackground from './components/CanvasBackground';

const App: React.FC = () => {
  return (
    <Router>
      <CanvasBackground />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/discogs-api" element={<DiscogsAPI />} />
        <Route path="/discogs-collection" element={<DiscogsCollection />} />
        <Route path="/no-dogs-allowed" element={<NoDogsAllowed />} />
        <Route path="/linkedin" element={<LinkedIn />} />
        <Route path="/github" element={<Github />} />
        <Route path="/djing" element={<DJing />} />
      </Routes>
    </Router>
  );
};

export default App;
