import React, { useEffect } from 'react';

const NoDogsAllowed: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://dog-free-pubs.koyeb.app/";
  }, []);

  return <p>Redirecting to No Dogs Allowed...</p>;
};

export default NoDogsAllowed;
