import React, { useEffect } from 'react';

const DiscogsCollection: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://www.discogs.com/user/rgbnathan/collection";
  }, []);

  return <p>Redirecting to Discogs Collection...</p>;
};

export default DiscogsCollection;
