import React from 'react';
import { Link } from 'react-router-dom';
import '../css/home.css';

// Reusable method to generate a card
const renderCard = (title: string, description: string, path: string) => (
  <div className="col-md-3" key={title}>
    <div className="card mb-4">
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        <Link to={path} className="btn btn-primary">Go to {title}</Link>
      </div>
    </div>
  </div>
);

// DJing Section Component
const DjingSection: React.FC = () => (
  <div className="mt-4">
    <h2>DJing</h2>
    <p>
      I enjoy sharing my music mixes with people. Check out my DJ sets on Instagram and see what I've been spinning.
    </p>
    <div className="row">
      {renderCard("DJing", "Check out my DJ sets on Instagram.", "/djing")}
      {renderCard("Vinyl Collection", "Check out my vinyl collection I have for DJ sets.", "/discogs-collection")}
    </div>
  </div>
);

// Apps I've Developed Section Component
const AppsSection: React.FC = () => (
  <div className="mt-4">
    <h2>Apps I've Developed</h2>
    <div className="row">
      {renderCard("Discogs Query API", "Explore the Discogs Query API for music data.", "/discogs-api")}
      {renderCard("No Dogs Allowed", "Find dog-free pubs in your area.", "/no-dogs-allowed")}
    </div>
  </div>
);

// Social Links Section Component
const SocialLinksSection: React.FC = () => (
  <div className="mt-4">
    <h2>Social Links</h2>
    <div className="row">
      {renderCard("GitHub", "Check out my projects on GitHub.", "/github")}
      {renderCard("LinkedIn", "Connect with me on LinkedIn.", "/linkedin")}
    </div>
  </div>
);

// Home Component
const Home: React.FC = () => (
  <div className="container mt-5">
    <h1>Welcome to Nathan's Link Page</h1>
    <p>This page provides links to my GitHub, Discogs Query API, No Dogs Allowed, LinkedIn profile, and my DJing adventures.</p>

    <div className="mt-4">
      <h2>About Me</h2>
      <p>
        I'm Nathan, a passionate Java developer with a focus on microservices and a love for music.
        I enjoy building applications that solve real-world problems while also expressing my creativity through DJing.
        My goal is to leverage technology and music to create impactful experiences.
      </p>
    </div>

    {/* DJing Section */}
    <DjingSection />

    {/* Apps I've Developed Section */}
    <AppsSection />

    {/* Social Links Section */}
    <SocialLinksSection />
  </div>
);

export default Home;
